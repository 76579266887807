<template>
  <section class="ministry-structure">
    <div
      v-for="(section, i) in sections.filter((s) => s.workers.length)"
      :key="i"
      class="ministry-structure__section"
    >
      <h2 :key="i" class="ministry-structure__section-title">{{ section.title }}</h2>
      <PersonComponent :data="section.workers.find((item) => item.is_director)" structure />
      <div class="ministry-structure__section-list">
        <PersonComponent
          small
          structure
          v-for="(worker, i) in section.workers.filter((w) => !w.is_director)"
          :key="i"
          :data="worker"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MINISTRY_STRUCTURE_PAGE from "gql/pages/MinistryStructurePage.graphql";
import PersonComponent from "components/PersonComponent.vue";

export default {
  name: "MinistryStructurePage",
  components: { PersonComponent },
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: MINISTRY_STRUCTURE_PAGE,
      })
      .then(({ data }) => {
        store.dispatch("ministry/save", data);
      })
      .catch(() => {});
  },
  computed: {
    sections() {
      return this.$store.state.sections;
    },
  },
  metaInfo: {
    title: "Структура",
  },
};
</script>

<style lang="stylus">
.ministry-structure {
  display flex
  flex-direction column
  gap 80px

  &__section {
    display flex
    flex-direction column
    gap 50px
  }

  &__section-title {
    font-size 1.5rem
    font-weight 500
    line-height 34px
    margin 0
  }

  &__section-list {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 30px
    +below(1024px) {
      grid-template-columns 1fr
    }
    +below(768px) {
      grid-gap 15px
    }
  }
}
</style>
